/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1900px) {

	.loader {
		.logo {width: 180px; height: 68px;} 
	}

	input, textarea, select {font-size: 14px;} 
	.wrapper {width: 1170px;} 
	
	.homepage header .wrapper, .homepage footer .wrapper {width: 1024px;} 

	.btn {
		height: 40px; line-height: 40px;
		&.btn-primary {
			font-size: 15px; padding: 0 30px;  
			&.sm {padding: 0 25px;} 
		}  
		&.btn-secondary {
			height: 40px; line-height: 35px;font-size: 15px;
			padding: 0 20px; margin: 0 5px; 
		}  
		&.btn-catalog { 
			font-size: 14px;
		}
	}
	.btn-play,
	.btn-play-sm {
		width: 70px; height: 70px; background-size: 100%;    
	}
	.btn-play {
		&.right {
			margin-top: -100px; margin-left: -100px; 
		}
	}
	.btn-post {
		&.bp-right {margin-top: -15px;} 
	}

	h3 {font-size: 20px;} 
	h4 {font-size: 18px;}

	header {
		.wrapper {
			width: 1170px; margin-top: 48px; 
			/*height: 100px;*/
			height: 70px;
		}  
		/*.left {padding: 35px 0;}    */
		.left {padding: 22px 0;}    
		.logo {margin-left: -18px; width: 120px; height: auto;}  
		.nav {
			ul {
				padding: 0 0 0 15px; 
				li {
					/*font-size: 15px; */
					font-size: 14px; 
					a {padding: 0 9px;} 
				}
			}
		}
		.right { 
			.box-other {
				/*padding: 35px 20px 0 20px; */
				padding: 22px 20px 0 20px; 
			}
			.box-lang {margin: 0 20px 0 0;}
			.box-search {margin: 0 20px 0 0;}
		}
		.join {
			/*a {font-size: 15px;} */
			a {font-size: 14px;} 
		}
	}

	/*.boxscroll-trigger {top: 40px;} */
	.boxscroll-trigger {top: 30px;}  

	.homepage {
		.wrapper {width: 905px;}  
		header, footer {
			/*.wrapper {width: 1170px;}    */
			.wrapper {width: 1024px;}    
		}
		.box-abs-md {
			bottom: 45px;
			&.btn-primary {
				&.sm {padding: 0 15px;} 
			}
		} 
	}
	.section-home {
		/*&.section-1 {background-size:103%;} 
		&.section-2 {background-size:103%;}*/ 
		&.section-1 {background-size:116%;} 
		&.section-2 {background-size:116%;}
		&.section-3 {
			/*background-size:103%;  */
			background-size:116%;  
			&:after {
				position:absolute; width: 100%; height: 100px; bottom: 0; left: 0; content: ""; 
				z-index: -1; display: none;     
				background: url('../images/material/bg-after-section-3.png') repeat left bottom;  
			} 
			&.space { 
				&:after {display:block;}  
			}
		}
		.row-text {
			/*height: 180px; */
			height: 140px; border-width: 4px;
			bottom: 0; 
			.left {width: 64%;}
			.right {width: 36%;}
			&.styled-text-2 {bottom: 0;} 
			&.styled-text-3 {bottom: 0;} 
		}
		.row-box {
			&.styled-1 {
				height: 596px; 
				/*background-size:90%;   */
				background-size:100%;   
				.wrapper {width: 905px; /*padding: 190px 0 0 0;*/ padding: 200px 0 0 0;}  
			}
			&.styled-2 {
				height: 472px; 
				/*background-size:90%;  */
				background-size:100%;  
				.wrapper {width: 905px; padding: 130px 0 0 0;}
			}
			&.styled-3 {
				/*height: 440px; */
				/*height: 475px; */
				height: 490px; 
				/*background-size:90%;  */
				background-size:100%;  
				.wrapper {width: 905px; padding: 150px 0 0 0;}
			}
		}
		.row-text {
			.wrapper {/*padding: 40px 0 0 60px;*/ padding: 20px 0 0 60px;}  
			/*h3 {font-size: 24px;} */
			h3 {font-size: 18px;} 
			/*.short-text {font-size: 16px;} */
			.short-text {font-size: 14px; line-height: 22px;} 
			.right {
				/*padding: 60px 0 0 0; */
				padding: 45px 0 0 0; 
				.ic-home {
					/*width: 60px; height: 54px; background-size: 100%;  */
					width: 50px; height: 44px; background-size: 100%;  
				}
			}
			&.styled-text-3 {bottom: 0;} 
		}	
	}
	.boxscroll-trigger {left: 0;} 
	.box-abs-md {
		bottom: 0; 
	}
	.boxlist-md {   
		height: 245px; background-size: 100%; background-position: 0 20px;   
		.box {
			width: 22.75%; margin: 0 3% 0 0; 
			&:before {
				background-size:100% 100%;  
			}
			&:nth-child(2) {margin-right: 3%;}  
			&:last-child {margin-right: 0;} 
			figure { 
				width: 110px; margin: 12px 0 0 48px; position: relative; overflow: hidden;   
				/*height: 77%; */
				height: 74.5%; 
			}
			.link-abs {width: 110px; height: 74.5%; margin: 12px 0 0 48px;}
			&.unfilled {
				&:before {background-size:100% 100%;} 
			}
		}  
	}
	.boxlist-lg {
		.box {
			width: 24%; height: 222px; margin: 20px 8% 0 0;
			figure {
				width: 166px; height: 83%; margin: 16px 0 0 25px;  
			} 
			.link-abs {width: 166px; height: 83%;margin: 16px 0 0 25px;} 
			&:before {background-size:100%;}
			&:last-child {margin-right:0;} 
			&:nth-child(2n+2) {
				width: 36%; height: 250px; margin-top: 0; 
				&:before {background-size:100%;} 
				figure {
					width: 183px; height: 84%; margin: 19px 0 0 70px;
				} 
				.link-abs {width: 183px; height: 84%;margin: 19px 0 0 70px;}
			} 
			
		}
	}
	.box-abs-lg {bottom: 25px;}
	.boxlist-sm {   
		height: 340px; background-size: 100%;   
		.box {
			width: 18.5%; margin: 0 2% 0 0; height: 170px; 
			&:before {
				background-size:100% 100%;  
			}
			&:nth-child(2n+2) {margin-right: 22%;}  
			&:last-child {margin-right: 0;} 
			figure { 
				width: 106px; height: 85%; margin: 10px 0 0 31px; position: relative; overflow: hidden;   
			} 
			.link-abs {width: 106px; height: 85%;margin: 10px 0 0 31px;}
		}  
	}
	.box-abs-sm {bottom: -5px;}  

	.middle {min-height: 540px;}  

	.contentpage {
		header {
			.wrapper {width: 1170px;} 
			.right { 
				.box-other {
					padding-right: 0; 
				}
			}
		}
		/*.middle {padding: 100px 0 0 0;} */
		.middle {padding: 72px 0 0 0;} 
	}

	.contentpage2 {
		header {
			.wrapper {width: 1170px;}  
			&.full {
				.wrapper {height: 100px;}				 
			}
		}
	}

	.row-main {
		padding: 40px 0 70px 0;  
		.side {
			&.sd-right {
				padding: 20px 30px; 
			}
		}
		.nav-part { 
			ul {
				li {
					a {height: 45px; line-height: 45px; padding: 0 20px;} 
				}
			}
		}
		.main-content {
			h1 {
				font-size: 22px;
				&.lg {font-size: 30px;} 
			} 
			.main-filter {
				h1 {} 
				&.no-border {
					margin-bottom: 30px; 
				}
			}
			.top-mid {
				.cl {font-size: 15px;} 
			}
			.if-youtube {
				/*iframe {height: 475px !important;}  */
			}
		}
		.article { 
			p {
				font-size: 15px; line-height: 28px; 
				&.med {font-size: 16px;} 
			}
			ul {
				li {font-size: 15px; line-height: 29px;}
			}
			h3 {font-size: 20px; line-height: 30px;}  
		}
		.box-btn { 
			padding: 0;  
			.btn {bottom: 0;} 
		}
		.notfound {			
			&.article {
				width: 400px; padding: 100px 0 0 0;  
				p {font-size: 15px; line-height: 26px;} 
			}
		}
	}

	.nav-sub {
		ul {
			li {
				a {
					font-size: 16px; padding: 0 0 20px 0;
				}
			}
		}
	}

	.box-cate-item {
		display: block; padding: 20px 25px 10px 25px; 
		.item {
			float: left; width: 33.333%; display: block; margin: 0 0 15px 0;
			&:nth-child(1) {width: 33.333%;} 
			&:nth-child(2) {width: 33.333%;} 
			&:nth-child(3) {width: 33.333%;} 
			&:nth-child(4) {width: 33.333%;} 
			&:nth-child(5) {width: 33.333%;} 
		}
	}

	.row-certificate { 
		.row {
			.list {width: 20%;}
		}
		.col {			
			&:first-child {
				padding-left: 0; 
			}
		}
	}

	.row-description { 
		padding: 25px 40px;  
	}

	.col-accordion {
		.acclist {
			.acc-title {font-size: 16px;} 
		}
	}

	.table-std {
		table {
			thead {
				th {
					font-size: 13px; padding: 18px 7px;
					&:first-child {padding-left:20px;} 
					&:last-child {padding-left:20px;} 
				} 
			}
			tbody {
				td {
					font-size: 13px; padding: 18px 7px;
					&:first-child {
						padding-left:20px;
						&:before {width:20px;}
					} 
					&:last-child {
						padding-left:20px;
						&:after {width:20px;}
					} 
				} 
			}
		}
	}

	.frame-listing { 
		.list-item {
			figure {
				height: 220px;  
			}
			.text {
				.date {font-size: 15px;}
				.short-descrip {font-size: 15px; line-height: 23px;}  
			}
		}
		&.col-2 {
			.list-item {
				figure {height: 255px;}
			}
		}
		&.other {
			padding: 40px; 
		}
	}

	.box-large {
		&.event {
			padding: 40px 60px; 
			.row {
				&.full {
					.col {
						&.text {
							p {
								font-size: 15px; line-height: 28px; 
							}
						}
						&.figure {
							&:before {
								background-size:75%; 
							}
							&:after {
								background-size:75%; 
							}
						}
					}
				}
			}
		}
	}

	.form-container {
		padding: 40px;
		.inner {padding: 40px;}
	}
	.form-std { 
		input {height: 45px; line-height: 45px; padding: 0 14px;}  
		.selectbox {height: 45px; line-height: 45px;}  
		.input_file {
			height: 45px; line-height: 45px; padding-right: 85px; 
			input {padding-left: 14px; padding-right: 85px;}   
			&:after {
				height:45px; line-height: 45px; width: 80px; font-size: 14px;     
			}
			span {
				&.filled {
					&:after {width:95px;} 
				}
			}
		}
	}
	.form-search {
		margin: 10px 0 40px 0;      
		input[type="text"] {font-size: 24px; line-height: 24px;}
	}
	.result-search { 
		padding: 0;
		.col {
			a {
				h3 {font-size: 18px; margin-bottom: 15px;}   
			}
		}
	}
	#boxPop {min-width: 600px; max-width: 600px;} 


	.row-404 {height: 640px;} 
	.part-vote {
		padding: 20px 30px;
		.left {padding: 5px 0 0 0;} 
	}

	.sitemap {
		.row {
			.col {
				h3 {font-size: 16px;} 
				ul {
					li {
						a {font-size: 15px;}  
					}
				}
			}
		}
	}


	footer {
		/*padding: 45px 0;  		 */
		padding: 20px 0; 
		.foot-top {
			padding: 0 0 15px 0;margin-bottom: 20px;
			.telp {font-size: 14px;}
			.nav-foot {
				ul {
					li {font-size: 14px;} 
				}
			}
		} 
		.foot-bottom {font-size: 14px;} 		 
	}
	
	@keyframes bounching {
	    0% { bottom: 282px; }
	    25% { bottom: 287px; }
	    50% { bottom: 282px;}
	    75% { bottom: 287px; }
	    100% { bottom: 282px; }
	}

}